
import { mapGetters } from 'vuex';
export default {
    props: ["idForm", "dataAddress", "dataListCountries", "dataListStates", "dataListPhoneCodes", "typeForm"],
    components: {
        vcFormField		: () => import("../../components/pages/form-field.vue")
    },
    data() {
        return {
            back_url             : process.env.VUE_APP_HEFESTO_API_URL_FILE,
            addressForm         : this.dataAddress,
            focusForm           : {
                first_name  : false,
                last_name   : false,
                phone       : false,
                address_1   : false,
                address_2   : false,
                company     : false,
                country     : false,
                state       : false,
                city        : false,
                zip         : false
            },
            listCountries       : [],
            listStates          : [],
            listPhoneCodes      : [],
            listStatesByCountry : [],
            
            phoneNumber         : "",
            countryPhoneCode    : "US-1",
            
            actionForm          : null
        }
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault()
                    }
                    return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    async mounted(){
        this.listStates     = this.dataListStates || [];
        this.listCountries  = this.dataListCountries || [];
        this.listPhoneCodes = this.dataListPhoneCodes || [];
        
        this.filterStates( this.addressForm.country_code );
        
        this.$root.$on('change-select-address', this.updateSelectedAddress);
        this.$root.$on('valid-address', this.executeValidAddress);
        this.$root.$on('reset-address', this.resetAddress);
        this.$root.$on('update-field-value', this.updateFieldValue);
    },
    computed: {
        ...mapGetters({
            getterCustomer          : "getterCustomer",
        }),
        getAddress(){
            let phone 				        = this.validPhoneNumber( this.addressForm.phone == '' ? this.getterCustomer?.phone : this.addressForm.phone );
            
            this.phoneNumber 		        = phone.number;
            this.countryPhoneCode 	        = phone.code;
            this.addressForm.phone          = phone.format;
            return this.addressForm;
        },
        getPhoneNumber(){
            return this.phoneNumber;
        },
        getPhoneCode(){
            return this.countryPhoneCode;
        },
        getPhoneData(){
            return { phoneNumber: this.getPhoneNumber, countryPhoneCode: this.getPhoneCode };
        },
        getFocusForm(){
            return this.focusForm;
        }
    },
    methods: {
        updateFieldValue(data){
            if( data.field == "phone" ){
                this.phoneNumber = data.value.phoneNumber;
                this.countryPhoneCode = data.value.countryPhoneCode;
            }
            else if( data.field == "country_code" && this.listCountries.find( (item) => item.id == data.value ) ){
                this.filterStates( data.value );
                this.addressForm.country_code = data.value;
            }
            else if( data.field == "state_code" && this.listStatesByCountry.find( (item) => item.id == data.value ) ){
                this.selectState( data.value );
                this.addressForm.state_code = data.value;
            }
            else if( data.field == "google_country_state_code" ){
                if( this.listCountries.find( (item) => item.id == data.value.country_code ) ){
                    this.filterStates( data.value.country_code );
                    this.addressForm.country_code = data.value.country_code;
                    if( this.listStatesByCountry.find( (item) => item.id == data.value.state_code ) ){
                        this.selectState( data.value.state_code );
                        this.addressForm.state_code = data.value.state_code;
                    }
                }
            }
            else{
                this.addressForm[data.field] = data.value;
            }
        },
        updateSelectedAddress( data_select ){
            
            if( this.idForm == data_select.id_form ){
                
                this.addressForm                = data_select.address;
                
                let currentCountry              = this.listCountries.find( (item) => item.id == this.addressForm.country_code );
                
                this.addressForm.country 		= currentCountry ? currentCountry.label : this.addressForm.country;
                this.addressForm.country_code 	= currentCountry?.id ? currentCountry.id : this.addressForm.country_code;
                
                let phone 				        = this.validPhoneNumber( this.addressForm.phone == '' ? this.getterCustomer?.phone : this.addressForm.phone );
                
                this.phoneNumber 		        = phone.number;
                this.countryPhoneCode 	        = phone.code;
                this.addressForm.phone          = phone.format;
                
                this.filterStates( this.addressForm.country_code );
            }
        },
        filterStates(country_id) {
            
            let itemCountry = this.listStates.find( (item) => item.id == country_id );
            
            this.$root.$emit('update-country-address', country_id);
            this.$root.$emit('update-state-address', this.addressForm.state_code);
            
            let exist_state = itemCountry ? itemCountry.states.some( (item) => item.id == this.addressForm.state_code) : false;
            
            this.listStatesByCountry        = itemCountry ? itemCountry.states: [];
            
            this.addressForm.state          = !exist_state ? ( this.listStatesByCountry.length > 0 ? this.listStatesByCountry[0].label : null ) : this.addressForm.state;
            this.addressForm.state_code     = !exist_state ? ( this.listStatesByCountry.length > 0 ? this.listStatesByCountry[0].id : null ) : this.addressForm.state_code;
            
            this.addressForm.country_code   = itemCountry ? itemCountry.id      : null;
            this.addressForm.country        = itemCountry ? itemCountry.label   : null;
        },
        selectState(state_id) {
            
            let itemState = this.listStatesByCountry.find( (item) => item.id == state_id );
            
            this.addressForm.state      = itemState ? itemState.label   : null;
            this.addressForm.state_code = itemState ? itemState.id      : null;
        },
        validPhoneNumber(phone){
            
            if( phone ){
                
                phone = phone.replace(/([ +])+/g, "");
                
                let code = phone.replace(phone.slice(-10), "");
                
                let index_country = code == "" ? -1 : this.listPhoneCodes.findIndex( (item) => item.phone_code == parseInt(code) );
                
                let country_code = ( code != "" && code == "1" ) || index_country < 0 ? "US-1" : this.listPhoneCodes[index_country].id;
                
                return {
                    code    : country_code,
                    number  : phone.slice(-10),
                    format  : `+${ country_code.split("-")[1] }${ phone.slice(-10) }`
                };
            }
            else{
                
                return {
                    code    : "US-1",
                    number  : "",
                    format  : ""
                };
            }
        },
        executeValidAddress(data_form){
            
            if( data_form.id_form == this.idForm ){
                
                this.actionForm = data_form.action;
                document.getElementById(`btn-${ data_form.id_form }`).click();
            }
        },
        resetAddress(data_form){
            
            if( data_form.clean ){
                
                this.filterStates("US");
                this.addressForm = {
                    id              : null,
                    customer_id     : null,
                    default         : false,
                    default_shipping: false,
                    default_billing : false,
                    first_name      : '',
                    last_name       : '',
                    name            : '',
                    phone           : '',
                    address_1       : '',
                    address_2       : '',
                    company         : '',
                    country         : 'United States',
                    country_code    : 'US',
                    state           : null,
                    state_code      : null,
                    city            : '',
                    zip             : ''
                };
                let phone 				        = this.validPhoneNumber( this.addressForm.phone );
                
                this.phoneNumber 		        = phone.number;
                this.countryPhoneCode 	        = phone.code;
                this.addressForm.phone          = phone.format;
            }
            document.getElementById(`reset-form-${ data_form.id_form }`).click();
        },
        preSubmit(is_valid){
            
            if( this.actionForm ){
                
                console.log( this.getAddress );
                this.$root.$emit('valid-form-address', { id_form: this.idForm, address: this.getAddress, valid: is_valid, action: this.actionForm });
            }
            this.actionForm = null;
        }
    }
}
