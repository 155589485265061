
import { mapGetters, mapActions } from "vuex";
import Notification from '../components/pages/notification.vue';
export default {
	loading: false,
	components: {
		headerSimple	: () => import( '../components/header/header-simple.vue' ),
		footerSimple	: () => import( '../components/footer/footer-simple.vue' )
	},
	data() {
		return {
			template_notification: null,
			store_path: this.$route.path
		}
	},
	head() {
		return {
			title: 'Cata1og.com | Wholesale Portal',
			script: [
			]
		}
	},
	async mounted() {
		
		await this.getGeneralSettings();
		this.template_notification = Notification;
		this.updateLoginUser( { token_login: this.$cookies.get('token-app'), data_user: this.$cookies.get('user-store'), current_route: this.$route.path } );
		
		await this.getGeneralSettings();
	},
	computed: {
		...mapGetters({
			getterNotification: 'getterNotification'
		}),
		getStorePath(){
			return this.store_path;
		},
		getNotification(){
			if( this.getterNotification?.type == 'error' ){
				
				if( this.getterNotification.data?.app_version != this.$cookies.get('app-version') ){
					
					this.$cookies.set('app-version', this.getterNotification.data.app_version, { path: '/', maxAge: 60 * 60 * 24 * 7 });
					window.location.reload();
				}
			}
			if( this.getterNotification?.data && this.getterNotification?.data.status != 403 ){
				
				let config_notification = {
					position: "top-right",
					timeout: [ "add-cart-product", "remove-cart-product", "update-cart-product", "save-later-product" ].includes(this.getterNotification.content.type) ? 2000 : 3000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: false,
					closeButton: "button",
					icon: ![ "add-cart-product", "remove-cart-product", "update-cart-product", "save-later-product" ].includes(this.getterNotification.content.type),
					rtl: false
				};
				const content = {
					component: this.template_notification,
					props: {
						title	: this.getterNotification.data.title,
						message	: this.getterNotification.data.message,
						content	: this.getterNotification.content
					}
				};
				
				this.$toast[this.getterNotification.type](content, config_notification);
				this.addNotification(null);
				
				return true;
			}
			else{
				return false;
			}
		}
	},
	methods: {
		...mapActions({
			getGeneralSettings	: 'getGeneralSettings',
			addNotification		: 'addNotification',
			logoutUser			: 'logoutUser',
			updateLoginUser		: 'updateLoginUser',
		})
	}
}
