
import { mapGetters, mapActions } from "vuex";
import Notification from '../components/pages/notification.vue';
export default {
	components: {
		headerDefault	: () => import( '../components/header/header-default.vue' ),
		footerDefault	: () => import( '../components/footer/footer-default.vue' )
	},
	data() {
		return {
			template_notification: null
		}
	},
	head() {
		return {
			title: 'Cata1og.com | Wholesale Portal',
			script: [
				{ async: true, defer: true, src: "https://config.gorgias.chat/bundle-loader/01HE3NT3H28Q860MN49NGFQYB8" }
			]
		}
	},
	async mounted() {
		
		this.template_notification = Notification;
		this.updateLoginUser( { token_login: this.$cookies.get('token-app'), data_user: this.$cookies.get('user-store'), current_route: this.$route.path } );
		
		await this.getGeneralSettings().then( (setting_result) => {
			this.addGclid();
		});
		window.addEventListener('scroll', this.handleScroll);
	},
	computed: {
		...mapGetters({
			getterNotification: 'getterNotification'
		}),
		getNotification(){
			if( this.getterNotification?.type == "error" && this.getterNotification?.data?.status == 403 ){
				
				this.logoutUser();
				this.$router.push('/account/login');
			}
			else if( this.getterNotification?.type == 'error' ){
				
				console.log( 'app-version' );
				console.log( 'app-version', this.$cookies.get('app-version') );
				let app_version = this.$cookies.get('app-version');
				if( app_version == undefined ){

					this.$cookies.set('app-version', this.getterNotification.data.app_version, { path: '/', maxAge: 60 * 60 * 24 * 7 });
				}
				if( this.getterNotification.data?.app_version ){
					
					if( this.getterNotification.data.app_version != app_version ){
						
						this.$cookies.set('app-version', this.getterNotification.data.app_version, { path: '/', maxAge: 60 * 60 * 24 * 7 });
						this.$cookies.remove("recentlyProd");
						this.$cookies.remove("general-settings");
						this.$cookies.remove("gclid");
						this.$cookies.remove("origin_add"); 
						this.$cookies.remove("utmSource"); 
						this.$cookies.remove("utmMedium");
						this.$cookies.remove("utmCampaign");
						this.$cookies.remove("utmTerm");
						window.location.reload();
					}
				}
			}
			if( this.getterNotification?.data && this.getterNotification?.data.status != 403 ){
				
				let config_notification = {
					position: "top-right",
					timeout: [ "add-cart-product", "remove-cart-product", "update-cart-product", "save-later-product" ].includes(this.getterNotification.content.type) ? 2000 : 3000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: false,
					closeButton: "button",
					icon: ![ "add-cart-product", "remove-cart-product", "update-cart-product", "save-later-product" ].includes(this.getterNotification.content.type),
					rtl: false
				};
				const content = {
					component: this.template_notification,
					props: {
						title	: this.getterNotification.data.title,
						message	: this.getterNotification.data.message,
						content	: this.getterNotification.content
					}
				};
				
				this.$toast[this.getterNotification.type](content, config_notification);
				this.addNotification(null);
				
				return true;
			}
			else{
				return false;
			}
		}
	},
	methods: {
		...mapActions({
			getGeneralSettings	: 'getGeneralSettings',
			addNotification		: 'addNotification',
			logoutUser			: 'logoutUser',
			updateLoginUser		: 'updateLoginUser',
		}),
		handleScroll(event){
			
			for (const item_notification of document.getElementsByClassName("Vue-Toastification__container")) {
				
				if( window.innerWidth > 991 && window.top.scrollY >= 134 ){
					
					item_notification.style = "top: 12px !important;";
				}
				else{
					
					item_notification.style = "";
				}
			}
		},
		getParamUrl(p) {
			
			let match = new RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
			return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
		},
		saveLocalParam(local_name, param){
			
			if( param ){
				
				this.$cookies.set(local_name, param, { path: '/', maxAge: 60 * 60 * 24 * 7 });
			}
		},
		getExpiryRecord(value) {
			let expiryPeriod = 90 * 24 * 60 * 60 * 1000; // caducidad de 90 días, en milisegundos
			
			let expiryDate = new Date().getTime() + expiryPeriod;
			return {
				value: value,
				expiryDate: expiryDate
			};
		},
		addGclid() {
			let gcl_id = {
				value: this.getParamUrl('gclid'),
				fields: ['gclid'],
				object_value: null,
				current_field: null,
				src_valid: !this.getParamUrl('gclsrc') || this.getParamUrl('gclsrc').indexOf('aw') !== -1,
				valid_value: false,
				current_object_value: null
			}
			if (gcl_id.value && gcl_id.src_valid) {
				
				gcl_id.object_value = this.getExpiryRecord(gcl_id.value);
				this.cookies.set('gclid', JSON.stringify(gcl_id.object_value), { path: '/', maxAge: 60 * 60 * 24 * 7 });
				this.cookies.set('add_origin', this.$route.fullPath, { path: '/', maxAge: 60 * 60 * 24 * 7 });
			}
			this.saveLocalParam("utmSource"		, this.getParamUrl('utm_source') ); 
			this.saveLocalParam("utmMedium"		, this.getParamUrl('utm_medium') );
			this.saveLocalParam("utmCampaign"	, this.getParamUrl('utm_campaign') );
			this.saveLocalParam("utmTerm"		, this.getParamUrl('utm_term') );
			
			gcl_id.current_object_value = gcl_id.object_value || ( this.$cookies.get('gclid') || null );
			gcl_id.valid_value = gcl_id.current_object_value && new Date().getTime() < gcl_id.current_object_value.expiryDate;
			
			if (gcl_id.valid_value) {
				
				for (const item_field of gcl_id.fields) {
					
					if (document.getElementById(item_field)) {
						
						document.getElementById(item_field).value = gcl_id.current_object_value.value;
					}
				}
			}
		}
	},
	beforeDestroy(){
		
		window.removeEventListener('scroll', this.handleScroll);
	}
}
